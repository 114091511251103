<template>
  <div>
    <div v-if="isLoading">
      <OtherLoading />
    </div>
    <div v-else>
      <HeaderPanel
        title="เช็คคะแนน"
        :filter="filter"
        @search="handleSearch"
        @clear="clearFilter"
      >
        <template v-slot:filter-title>
          <b-form-checkbox
            class="mt-2"
            v-model="filter.currentOrAll"
            :value="1"
            :unchecked-value="0"
            @change="handleChecked"
          >
            แสดงลูกค้าที่มียอดซื้อสาขาล่าสุด สาขา {{ branchName }}
          </b-form-checkbox>
        </template>
        <template v-slot:filter-option>
          <b-col>
            <InputText
              v-model="filter.member_id"
              textFloat="หมายเลขสมาชิก (Member No.)"
              placeholder="หมายเลขสมาชิก (Member No.)"
              type="text"
              name="point_balance_member_id"
              className="mb-2"
              @onEnter="handleSearch"
            />
          </b-col>
          <b-col>
            <InputText
              v-model="filter.name"
              textFloat="ชื่อสมาชิก (Member Name)"
              placeholder="ชื่อสมาชิก (Member Name)"
              type="text"
              name="point_balance_name"
              className="mb-2"
              @onEnter="handleSearch"
            />
          </b-col>
          <b-col>
            <InputText
              v-model="filter.telephone"
              textFloat="เบอร์โทรศัพท์ (Telephone)"
              placeholder="เบอร์โทรศัพท์ (Telephone)"
              type="text"
              name="point_balance_telephone"
              className="mb-2"
              @onEnter="handleSearch"
            />
          </b-col>
          <b-col>
            <b-form-group class="filter-checkbox">
              <template #label>
                <b class="f-size-14">ระดับสมาชิก (Member Type) </b>
              </template>

              <template v-slot="{ ariaDescribedby }">
                <b-form-checkbox
                  class="f-size-14 w-33 pr-1 mr-0"
                  v-model="memberTypeOptions"
                  aria-describedby="memberType"
                  aria-controls="memberType"
                  @change="memberTypeToggle"
                  ref="checkAll"
                >
                  All
                </b-form-checkbox>
                <b-form-checkbox-group
                  v-model="filter.memberLevel"
                  :aria-describedby="ariaDescribedby"
                  class="f-size-14"
                >
                  <b-row>
                    <b-col cols="6" v-for="opt of memberTypeList" :key="opt.id">
                      <b-form-checkbox
                        class="pr-1 mr-0"
                        :value="opt.id"
                        :id="opt.name"
                        >{{ opt.name }}
                      </b-form-checkbox>
                    </b-col>
                  </b-row>
                </b-form-checkbox-group>
              </template>
            </b-form-group>
          </b-col>
        </template>
      </HeaderPanel>
      <div class="bg-white border-red mt-3">
        <b-row>
          <b-col>
            <b-table
              responsive
              striped
              hover
              :fields="fields"
              :items="items"
              :busy="isBusy"
              show-empty
              empty-text="No matching records found"
              class="table-main"
              :no-border-collapse="false"
            >
              <template v-slot:cell(member_id)="data">
                <div class="text-center">
                  <p>
                    {{ data.item.name }} {{ data.item.middlename }}
                    {{ data.item.surname }}
                  </p>
                  <router-link
                    :to="`/report/history-sale-category?memberId=${data.item.member_id}`"
                  >
                    <u> {{ data.item.member_id }}</u>
                  </router-link>
                </div>
              </template>
              <template v-slot:cell(points_expiring_soon)="data">
                <template
                  v-if="
                    data.item.points_expiring_soon &&
                    data.item.points_expiring_soon.point
                  "
                >
                  <div>
                    {{ data.item.points_expiring_soon.point | numeral("0,0") }}
                    <br />
                    {{ data.item.points_expiring_soon.expiring_soon }}
                  </div>
                </template>
                <template v-else>-</template>
              </template>
              <template v-slot:cell(last_branch_purched_time)="data">
                <template v-if="data.item.last_branch_purched_time">
                  <div>
                    {{ data.item.last_branch_purched_time }}
                  </div>
                </template>
                <template v-else>-</template>
              </template>
              <template v-slot:cell(center_point)="data">
                <template v-if="data.item.center_point">
                  <div>
                    {{ data.item.center_point | numeral("0,0") }}
                  </div>
                </template>
                <template v-else>-</template>
              </template>
              <template v-slot:cell(last_branch_purched_name)="data">
                <template v-if="data.item.last_branch_purched_name">
                  <div>
                    {{ data.item.last_branch_purched_time }}
                  </div>
                  <div>
                    {{ data.item.last_branch_purched_name }}
                  </div>
                </template>
                <template v-else>-</template>
              </template>
              <template v-slot:cell(is_consent)="data">
                <div v-if="data.item.is_consent">ยินยอม</div>
                <div v-else>รอยินยอม</div>
              </template>
              <template v-slot:cell(action)="data">
                <div class="d-flex justify-content-center align-items-center">
                  <font-awesome-icon
                    icon="search"
                    class="pointer mr-2"
                    @click="$refs.modalPoint.show(data.item)"
                  />
                  <img
                    src="@/assets/images/icons/note.png"
                    alt="transfer"
                    class="action-img pointer"
                    width="20"
                    center
                    @click="$refs.modalNote.show(data.item)"
                  />
                </div>
              </template>
              <template v-slot:table-busy>
                <div class="text-center text-black my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong class="ml-2">Loading...</strong>
                </div>
              </template>
            </b-table>
          </b-col>
        </b-row>
        <Pagination
          @handleChangeTake="handleChangeTake"
          :pageOptions="pageOptions"
          :filter="filter"
          :rows="rows"
          @pagination="pagination"
        />
      </div>
    </div>
    <ModalPoint ref="modalPoint" :branchId="branchId" />
    <ModalNote ref="modalNote" :branchId="branchId" />
  </div>
</template>

<script>
import OtherLoading from "@/components/loading/OtherLoading";
import HeaderPanel from "@/components/HeaderPanel";
import Pagination from "@/components/Pagination";
import InputText from "@/components/inputs/InputText";
import ModalPoint from "@/components/report/pointBalance/ModalPoint";
import ModalNote from "@/components/report/pointBalance/ModalNote";
import { mapGetters } from "vuex";

export default {
  components: {
    OtherLoading,
    Pagination,
    HeaderPanel,
    InputText,
    ModalPoint,
    ModalNote,
  },
  name: "ReportPointBalance",
  data() {
    return {
      fields: [
        {
          key: "member_id",
          label: "หมายเลขสมาชิก",
          thStyle: { width: "20px" },
        },
        {
          key: "telephone",
          label: "เบอร์โทรศัพท์",
          thStyle: { width: "20px" },
        },
        {
          key: "branch_point",
          label: "คะแนนสาขา",
          thStyle: { width: "20px" },
        },
        {
          key: "center_point",
          label: "คะแนนสะสม",
          thStyle: { width: "20px" },
        },
        {
          key: "points_expiring_soon",
          label: "คะแนนที่จะหมดอายุ",
          thStyle: { width: "20px" },
        },
        {
          key: "member_type",
          label: "ประเภทสมาชิก",
          thStyle: { width: "20px" },
        },
        {
          key: "last_branch_purched_time",
          label: "วันที่ซื้อล่าสุด",
          thStyle: { width: "20px" },
        },
        {
          key: "last_branch_purched_name",
          label: "สาขาที่ซื้อล่าสุด",
          thStyle: { width: "120px" },
        },
        {
          key: "is_consent",
          label: "Consent status",
          thStyle: { width: "20px" },
        },
        {
          key: "action",
          label: "",
          tdClass: "text-center",
          thStyle: { width: "20px" },
        },
      ],
      items: [],
      isBusy: false,
      isLoadingData: false,
      rows: 0,
      filter: {
        currentOrAll: 1,
        search: "",
        page: 1,
        take: 10,
        member_id: "",
        name: "",
        telephone: "",
        memberLevel: [],
        branchId: 0,
      },
      pageOptions: [
        { value: 5, text: "5 / page" },
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],
      selected: 0,
      isLoading: true,
      memberTypeList: [],
      memberTypeOptions: true,
    };
  },
  computed: {
    ...mapGetters({
      branchId: "getBranchId",
      branchName: "getBranchName",
    }),
  },
  watch: {
    "filter.memberLevel"(newValue) {
      if (newValue.length === 0) {
        this.memberTypeOptions = false;
      } else if (newValue.length === this.memberTypeList.length) {
        this.memberTypeOptions = true;
      } else {
        this.memberTypeOptions = false;
      }
    },
  },
  created: async function () {
    this.filter.branchId = this.branchId;
    await this.getMemberLevel();
    await this.memberTypeToggle(true);
    await this.getList();
    const theme = await this.axios(`${this.$baseUrl}/customer/theme`);
    if (theme.is_center_point == 1) {
      this.fields = [
        {
          key: "member_id",
          label: "หมายเลขสมาชิก",
          thStyle: { width: "20px" },
        },
        {
          key: "telephone",
          label: "เบอร์โทรศัพท์",
          thStyle: { width: "20px" },
        },
        // {
        //   key: 'branch_point',
        //   label: 'คะแนนสาขา',
        //   thStyle: { width: "20px" }
        // },
        {
          key: "center_point",
          label: "คะแนนสะสม",
          thStyle: { width: "20px" },
        },
        {
          key: "points_expiring_soon",
          label: "คะแนนที่จะหมดอายุ",
          thStyle: { width: "20px" },
        },
        {
          key: "member_type",
          label: "ประเภทสมาชิก",
          thStyle: { width: "20px" },
        },
        {
          key: "last_branch_purched_time",
          label: "วันที่ซื้อล่าสุด",
          thStyle: { width: "20px" },
        },
        {
          key: "last_branch_purched_name",
          label: "สาขาที่ซื้อล่าสุด",
          thStyle: { width: "120px" },
        },
        {
          key: "is_consent",
          label: "Consent status",
          thStyle: { width: "20px" },
        },
        {
          key: "action",
          label: "",
          tdClass: "text-center",
          thStyle: { width: "20px" },
        },
      ];
    }
  },
  methods: {
    getList: async function () {
      this.isBusy = true;
      let payload = { ...this.filter };
      payload.skip = (payload.page - 1) * payload.take;
      payload.member_level = payload.memberLevel.toString();
      payload.branch_id = this.filter.branchId;
      this.isLoadingData = true;
      await this.axios
        .post(`${this.$baseUrl}/report/get_point_balance`, payload)
        .then((data) => {
          this.isLoading = false;
          if (data.result == 1) {
            this.items = data.detail;
            this.rows = data.total_count;
            this.isLoadingData = false;
          }
        });

      this.isBusy = false;
    },
    getMemberLevel: async function () {
      await this.axios
        .get(`${this.$baseUrl}/dropdown/get_member_level`)
        .then((data) => {
          if (data.result == 1) {
            this.memberTypeList = data.detail;
          }
        });
    },
    handleChecked(val) {
      if (val) this.filter.branchId = this.branchId;
      else this.filter.branchId = 0;

      this.pagination(1);
    },
    memberTypeToggle(checked) {
      this.filter.memberLevel = checked
        ? this.memberTypeList.map((el) => el.id)
        : [];
    },
    handleSearch(value) {
      this.pagination(1);
    },
    pagination(page) {
      this.filter.page = page;
      this.getList();
    },
    handleChangeTake(value) {
      this.filter.page = 1;
      this.filter.take = value;
      this.getList();
    },
    async clearFilter() {
      this.filter = {
        search: "",
        page: 1,
        take: 10,
        member_id: "",
        name: "",
        telephone: "",
        memberLevel: [],
      };
      this.memberTypeOptions = true;
      await this.memberTypeToggle(true);

      this.getList();
    },
  },
};
</script>

<style lang="scss" scoped>
.time {
  color: #afafaf;
}

.line-clamp2 {
  word-break: break-word;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.badge {
  font-size: 85%;
  background-color: var(--secondary-color);
  color: var(--primary-color);
  max-width: max-content;
  font-weight: 100;
}

.badge-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.icon {
  font-size: 24px;
  color: #000 !important;
}

::v-deep .table-main {
  table {
    min-width: max-content;
  }
}

@media (max-width: 768px) {
  .filter-checkbox {
    .custom-checkbox {
      width: 100%;
    }
  }
}
</style>
